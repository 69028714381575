<template>
  <div>
    <div v-if="isSuccess" class="text-center success--text">
      {{ $t("messages.connect_e_market_account_success") }}
    </div>
    <div v-else-if="isError" class="text-center error--text">
      {{ $t("messages.system_error_try_again") }}
    </div>
    <div v-else class="text-center warning--text">
      {{ $t("messages.loading") }}
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "EMarketAuth",
  data: () => ({
    isSuccess: false,
    isError: false,
  }),
  mounted() {
    this.getAccessToken();
  },
  methods: {
    async getAccessToken() {
      const e_market = this.$route.params.eMarket;
      const code = this.getUrlParameter("code");
      const state = this.getUrlParameter("state");
      const shop_id = this.getUrlParameter("shop_id");
      try {
        await httpClient.post("/get-e-market-token", {
          e_market,
          code,
          state,
          shop_id,
        });
        this.isSuccess = true;
        this.$vToastify.success(
          this.$t("messages.connect_e_market_account_success")
        );
        setTimeout(() => {
          this.$router.push({ name: "Account" });
        }, 3000);
      } catch (e) {
        this.isError = true;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
